import $ from 'jquery'
import { setupApp } from './common/setup-app/setup-app'
import 'bootstrap'
import 'slick-carousel'
import './configuration'

import './landing-pages/site'

window.$ = window.jQuery = $

setupApp()

if (!Spark.hideNav) {
  import('./landing-pages/stripe-menu')
}
